<script>

  export default {
    props: {
      searchActive: false,
      frontendUrl: null
    },
    data: ()=>({
      query: '',
      results: []
    }),
    computed: {
      seeAllUrl: function() {
        return `/search/?q=${this.query}`;
      }
    },
    mounted() {
      let panel = this.$refs.searchPanel;
      
      // Set Focus + Query After Panel Animation
      // @TD: Fix Bug: This Happens on Resize
      
      panel.addEventListener('transitionend', (e) => {
        if(e.propertyName == 'transform') {
          
          if(this.searchActive) {
            this.$refs.input.focus(); // Focus input field
          } else {
            
            // Blur Input Field or Return Focus to Magnifying Glass
            // (when focused on input programmatically setting focus will force :focus-visible)
            
            if(document.activeElement === this.$refs.input) {
              this.$refs.input.blur(); 
            } else {
              this.$refs.searchToggleButton.focus();
            }
            this.query = ''; // Reset Query 
          }
          console.log(e);
        }
      });
    },
    methods: {
      submitSearch() {
        window.location = `/search/?q=${encodeURIComponent(this.query)}`
      },
      onKeyPress: _.debounce(function() {
        this.search(encodeURIComponent(this.query));
      }, 800),
      search: function(q) {
        console.log("Searching for ", q);
        let self = this;
        let headers = {
          'Content-Type': 'application/json'
        };
        console.log("Front end url ", self.frontendUrl);
        this.$http.get("/search/auto-suggest/", {
          params: {
            q: encodeURIComponent(q)
          },
          baseURL: self.frontendUrl,
          headers: headers
        }).then((d) => {
          // console.log("d ", d);
          self.results = d.data;
          return d;
        }).catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
      }
    },
    watch: {
      query(val) {
        if(this.query.length > 2) {
          this.$emit('searchResultsState', true);
        } else {
          this.$emit('searchResultsState', false);
        }
      }
    }
  }

</script>

<template>

  <section class="search" role="search">
  
    <button
      class="search-button"
      @click="$emit('searchState')"
      ref="searchToggleButton"
      aria-haspopup="true"
      aria-controls="searchPanel"
      :aria-expanded="searchActive"
    >
      <span class="icon-search" aria-hidden="true"></span>
      <span class="sr-only">Toggle Site Search Panel</span>
    </button>

    <div class="search-panel">
      <div class="search-row-mask">
        <div class="search-row" ref="searchPanel" id="searchPanel">
          <div class="container">
            <form @submit.prevent="submitSearch">
              <div class="search-form-action display-none display-lg-block">
                <button aria-label="search" class="search-form-button">
                  <span class="icon-search" aria-hidden="true"></span>
                </button>
              </div>
              <label for="site-search" class="sr-only">Search the site:</label>
              <input
                autocomplete="off"
                type="text"
                id="site-search"
                name="q"
                aria-label="Search through site content"
                placeholder="Enter Search Term"
                ref="input"
                @keydown="onKeyPress"
                v-model="query"
              >
              <button
                type="button"
                class="btn-close"
                @click="$emit('searchState', false)"
              >
                <span class="icon-close-circle" aria-hidden="true"></span>
                <span class="sr-only">Close Search Panel</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="search-panel-results">
        <div class="container">
          <ul class="search-panel-results-list">
            <li class="all-results">
              <div class="row">
                <div class="search-panel-result col-sm-16 col-lg-10"><a :href="seeAllUrl" class="result">See all results for “{{query}}”</a></div>
              </div>
            </li>
            <li v-for="(result, index) in results" :key="'result-' + index">
              <div class="row">
                <div class="search-panel-result col-sm-16 col-lg-10">
                  <a :href="result.url" class="result" v-html="result.title"></a>
                </div>
                <div class="result-section col-sm-16 col-lg-4 display-none display-lg-block">
                  <a :href="result.section_url">{{result.type}}</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
  </section>

</template>