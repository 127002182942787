var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive && !_vm.isTabbed),expression:"isActive && !isTabbed"}],staticClass:"module carousel",class:[
    _vm.type,
    _vm.backgroundColor,
    {wide: _vm.wide},
    {'narrow-column': _vm.narrowColumn},
    {'side-title': _vm.sideTitle},
    {'top-title': !_vm.sideTitle},
    {'scrollbar': _vm.scroll},
    {'featured': _vm.featured},
    {'no-buttons' : _vm.hideTools }
  ]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row carousel-row"},[_c('header',{staticClass:"carousel-title",class:_vm.headerClass},[_c('div',[_c('h2',{staticClass:"carousel-header",class:_vm.titleClass,domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),(_vm.description)?_c('p',{staticClass:"carousel-description mb-none",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e()]),_vm._v(" "),(_vm.showReadMore)?_c('a',{staticClass:"btn-cta basic read-more",attrs:{"href":_vm.readMore.url}},[_vm._v("\n          "+_vm._s(_vm.readMore.label)+"\n          "),_vm._m(0)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"carousel-column",class:_vm.gridClass},[_c('div',{ref:"swiper",staticClass:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._t("swiper-slides")],2),_vm._v(" "),_c('div',{staticClass:"carousel-tools"},[_c('ul',[_c('li',[_c('button',{ref:"swiperPrev",staticClass:"swiper-button-prev"},[_c('span',{staticClass:"icon-arrow-forward-small"})])]),_vm._v(" "),_c('li',[_c('button',{ref:"swiperNext",staticClass:"swiper-button-next"},[_c('span',{staticClass:"icon-arrow-forward-small"})])])]),_vm._v(" "),(_vm.scroll)?_c('div',{ref:"swiperScroll",staticClass:"swiper-scrollbar"}):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"circle-icon",attrs:{"aria-hidden":"true"}},[_c('span',{staticClass:"icon-carrot-circle off"}),_vm._v(" "),_c('span',{staticClass:"icon-carrot-circle-solid on"})])}]

export { render, staticRenderFns }