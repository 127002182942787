<script>

  import Vue from 'vue';
  import axios from 'axios';

  import menu from './header-components/menu';
  import searchPanel from './header-components/search-panel';
  import subMenu from './header-components/sub-menu';
  
  import * as focusTrap from 'focus-trap';

  import { state } from './state';

  export default {
    components: {
      'menu-list': menu,
      'search-panel': searchPanel,
      'sub-menu': subMenu,
    },
    data: ()=>({
      root: document.querySelector(':root'),
      mainIndicatorPos: 0,
      mainIndicatorWidth: 0,
      secondaryIndicatorPos: 0,
      secondaryIndicatorWidth: 0,
      hamburgerActive: false,
      searchActive: false,
      searchResultsActive: false,
      subnavActive: false,
      subnavData: {},
      activeSubnav: null,
      appState: state,
      collapsed: false,
      scrolled: false,
      trap: null,
      mouseHasMoved: false
    }),
    props: {
      logo: null,
      printLogo: null,
      menu: null,
      frontendUrl: "",
      locale: "",
      section: ""
    },
    methods: {

      menuAction(options) {

        // Do Not Allow a Mouse Enter until Mouse Movement Detected
        // Intended to prevent menu from opening immediately on navigating to a new page
        
        if(options.type == "menuEnter" && options.eventType == "mouseenter" && !this.mouseHasMoved) {
          return false;
        }
        
        // Populate Submenu

        if(options.hasMenu) {
          if(options.menu === 'main') {
            this.subnavData = this.menu.leftHandMenuItems[options.index];
          } else if(menu === 'secondary') {
            this.subnavData = this.menu.rightHandMenuItems[options.index];
          }
        }

        // Position Indicator (Desktop View Only)

        this.updateIndicator(options);

        // Toggle SubMenu (Touch Devices Desktop View Only)
        
        if(this.appState.menu == 'desktop' && this.appState.isTouch) {
          if(this.activeSubnav == options.mid) {
            this.subnavActive = false;
            return;
          }
        }

        // Activate Submenu

        if(options.hasMenu) {
          if(options.type == "menuLeave") {
            this.subnavActive = false;
            this.activeSubnav = null;
          } else {
            this.subnavActive = options.hasMenu;
            this.activeSubnav = options.mid;
          }
        }

      },

      menuActionClick(options) {

        if(options.hasMenu) {

          // Close Submenu If Open or Populate and Show
          
          if(options.mid == this.activeSubnav) {

            this.subnavActive = false;
            this.activeSubnav = null;

          } else {

            // Populate Submenu

            if(options.menu === 'main') {
              this.subnavData = this.menu.leftHandMenuItems[options.index];
            } else if(menu === 'secondary') {
              this.subnavData = this.menu.rightHandMenuItems[options.index];
            }

            // Activate SubMenu

            this.subnavActive = options.hasMenu;
            this.activeSubnav = options.mid;
          
          }

          // Update Indicator Position

          this.updateIndicator(options);

        }

      },

      updateIndicator(options) {

        if(this.appState.menu == 'desktop') {
          if(options.menu === 'main') {
            this.mainIndicatorPos = options.left;
            this.mainIndicatorWidth = options.width;
          } else {
            this.secondaryIndicatorPos = options.left;
            this.secondaryIndicatorWidth = options.width;
          }
        }

      },

      toggleSearch() {
        this.searchActive =! this.searchActive;
      },

      toggleSearchResults(searchResultsStatus) {
        this.searchResultsActive = searchResultsStatus;
      },

      toggleHamburger() {
        if(this.subnavActive) {
          this.deactivateSubnavThanHamburger();
        } else {
          this.hamburgerActive =! this.hamburgerActive;
        }
      },

      deactivateSubnavThanHamburger() {
        // When SubNav is Open In Mobile, Sequence Hamburger to Close After Subnav Slides Back
        if(this.appState.menu == 'mobile') {
          this.subnavActive = false;
          this.$refs.navContainer.addEventListener('transitionend', (e) => {
            this.hamburgerActive = false;
          }, { once: true });
        }
      },

      subNavState(val) {
        this.subnavActive = val;
      },

      deactiveSubNavWithEscape(e) {
        if (e.key === 'Escape') {
          console.log('close');
          this.subnavActive = false;
        }
      },

      deactiveSearchWithEscape(e) {
        if (e.key === 'Escape') {
          this.searchActive = false;
        }
      }

    },
    watch: {
      mainIndicatorPos: function (val) {
        this.root.style.setProperty('--main-menu-position', `${val}px`);
      },
      mainIndicatorWidth: function (val) {
        this.root.style.setProperty('--main-menu-width', `${val}px`);
      },
      secondaryIndicatorPos: function (val) {
        this.root.style.setProperty('--secondary-menu-position', `${val}px`);
      },
      secondaryIndicatorWidth: function (val) {
        this.root.style.setProperty('--secondary-menu-width', `${val}px`);
      },
      searchActive: function (val) {
        if(val) {
          if(this.appState.menu == 'mobile') {
            if(this.subnavActive) {
              this.deactivateSubnavThanHamburger();
            } else if(this.hamburgerActive) {
              this.hamburgerActive = false;
            }
          } else {
            this.subnavActive = false;
          }
        }
      },
      subnavActive: function (val) {
        if(val) {
          this.searchActive = false;
          document.body.classList.add('locked');
          document.addEventListener('keydown', this.deactiveSubNavWithEscape, false);
          this.$nextTick(() => {
            this.trap.activate(); 
          });
        } else {
          this.activeSubnav = null;
          document.body.classList.remove('locked');
          document.removeEventListener('keydown', this.deactiveSubNavWithEscape, false);
          this.$nextTick(() => {
            this.trap.deactivate(); 
          });
        }
      },
      searchActive: function (val) {
        if(val) {
          document.addEventListener('keydown', this.deactiveSearchWithEscape, false);
        } else {
          document.removeEventListener('keydown', this.deactiveSearchWithEscape, false);
          this.searchResultsActive = false;
        }
      },
      searchResultsActive: function (val) {
        if(val) {
          document.body.classList.add('locked');
        } else {
          document.body.classList.remove('locked');
        }
      },
      collapsed: function (val) {
        if(val) {
          this.searchActive = false;
          this.subnavActive = false;
          this.hamburgerActive = false;
        }
      },
      appState: {
        handler(val) {
          if(val.menu === 'desktop') {
            this.hamburgerActive = false;
            this.searchActive = false;
            this.subnavActive = false;
          }
        },
        deep: true
      }
    },
    mounted() {

      // FocusTrap Setup

      this.trap = focusTrap.createFocusTrap(this.$refs.subMenu, {
        allowOutsideClick: true
      });

      // Collapsing Nav Setup

      let lastScrollTop = 0;

      const addCollapse = () => {
        if(this.hamburgerActive) {
          return;
        }
        let st = document.documentElement.scrollTop;
        this.collapsed = (st > lastScrollTop) ? true : false;
        this.scrolled = (st > 8) ? true : false;
        lastScrollTop = st <= 0 ? 0 : st;
      }

      window.addEventListener('scroll', ()=> {
        requestAnimationFrame(addCollapse);
      }, true);

    }
  }

</script>

<template>

  <header
    class="site-header"
    v-if="menu"
    @mousemove.once="mouseHasMoved = true"
    :class="{
      'menu-active' : hamburgerActive,
      'search-active' : searchActive,
      'search-results-active' : searchResultsActive,
      'subnav-active' : subnavActive,
      'collapsed' : collapsed,
      'scrolled' : scrolled
    }"
  >

    <div class="main-header">
      <div class="container">
        <div class="header-row">

          <div class="hamburger display-lg-none">
            <button
              aria-label="Toggle Main Menu"
              aria-haspopup="true"
              aria-controls="navPanel"
              :aria-expanded="hamburgerActive"
              type="button"
              @click="toggleHamburger"
            >
              <span class="icon-hamburger" aria-hidden="true"></span>
              <span class="icon-close" aria-hidden="true"></span>
            </button>
          </div>

          <div class='logo'>
            <a href="/">
              <img :src="logo" alt="Fish and Richardson Logo" class="screen-only" width="100" height="29">
              <img :src="printLogo" alt="Fish and Richardson Logo" class="print-only" width="100" height="29">
              <span class="sr-only">Fish and Richardson </span>
            </a>
          </div>

          <nav id="navPanel">

            <div class="nav-container" ref="navContainer">

              <div class="top-menus">
                <menu-list
                  menu="main"
                  classname="main-menu"
                  label="Main Menu"
                  :menuItems="menu.leftHandMenuItems"
                  :activeSubnav="activeSubnav"
                  :section="section"
                  @menuEnter="menuAction"
                  @menuBlur="menuAction"
                  @menuLeave="menuAction"
                  @menuClick="menuAction"
                />

                <menu-list
                  menu="secondary"
                  classname="secondary-menu"
                  label="Secondary Menu"
                  :menuItems="menu.rightHandMenuItems"
                  :section="section"
                  @menuEnter="menuAction"
                  @menuLeave="menuAction"
                  
                />

              </div>

              <div class="alt-menus">
                <ul class="social-menu" aria-label="Social Networks Menu">
                  <li class="facebook">
                    <a href="https://www.facebook.com/fishrichardson/?fref=ts" target="_blank">
                      <span class="icon-facebook" aria-hidden="true"></span>
                      <span class="sr-only">Facebook</span>
                    </a>
                  </li>
                  <li class="twitter">
                    <a href="https://twitter.com/fishrichardson" target="_blank">
                      <span class="icon-twitter" aria-hidden="true"></span>
                      <span class="sr-only">Twitter</span>
                    </a>
                  </li>
                  <li class="linkedin">
                    <a href="https://www.linkedin.com/company/fish-&-richardson-p-c-/"  target="_blank">
                      <span class="icon-linkedin" aria-hidden="true"></span>
                      <span class="sr-only">Linked In</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <search-panel
            :frontend-url="frontendUrl"
            @searchState="toggleSearch"
            @searchResultsState="toggleSearchResults"
            :search-active="searchActive"
          />

        </div>
      </div>
    </div>

    <div ref="subMenu" id="subMenu">
      <sub-menu
        :frontend-url="frontendUrl"
        :subnavData="subnavData"
        @subMenuActive="subNavState"
      />
    </div>

  </header>

</template>
