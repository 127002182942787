<script>
  export default {
    data: function() {
      return {
        showAdvancedSearch: false,
        selectedFilters: [],
        selectedLetter: "",
        searchedQuery: "",
        isPreviousSearch: false,
      }
    },
    props: {
      label: {
        type: String,
        default: "Filter"
      },
      params: {
        type: Object,
        default: {}
      },
      hideAdvancedSearch: {
        type: Boolean,
        default: false
      },
      slug: {
        type: String,
        default: ""
      },
    },
    mounted: function() {
      const filterValues = Object.fromEntries(Object.entries(this.params).filter(([key,value]) =>  {
       return value !== "" && (key.includes("_id") || key.includes("selected_"))
      }));

      this.selectedFilters = Object.keys(filterValues);
      this.searchedQuery = this.params?.q || "";
      this.selectedLetter = this.params?.letter || "";

      if(this.selectedFilters.length > 0 || this.searchedQuery !== "" || this.selectedLetter !== "") {
        this.isPreviousSearch = true;
      }

      this.$root.$on("search-added", (value) => {
        if(!this.selectedFilters?.includes(value)) {
          this.selectedFilters.push(value);
        }
      });

      this.$root.$on("search-removed", (value) => {
        const searchTags = document.getElementById('search-tags');
        if(searchTags) {
          searchTags.querySelectorAll('li').forEach(e => {
            if(e.dataset?.filter_type===value) {
              // e.remove();
            }
          });
        }

        this.selectedFilters = this.selectedFilters.filter(e=>value!==e);
      });
      this.$root.$on("simple-search", (value) => {
        this.simpleSearch = value;
      });

      this.$root.$on("search-updated", () => {
        this.isPreviousSearch = false;
      });

      setTimeout(() => {
        this.searchScroll();
      }, 500);
    },
    computed: {
      hasEmptyParams: function() {
        const obj = {...this.params}
        delete obj["page"];
        delete obj["action"];
        delete obj["controller"];
        delete obj["post_type"];
        return Object.values(obj).length  > 0 && Object.values(obj).every((x) => x === "")
      },
      selectedFilters2: function() {
        return [];
      },
      displayLabel: function() {
        const label = this.selectedFilters.length > 0 ? 'Refine search' : 'Advanced search';
        return this.showAdvancedSearch ? 'Clear search' : label;
      },
      disableButton: function() {
        return !this.isPreviousSearch && this.showAdvancedSearch && this.searchedQuery === "" && this.selectedLetter === "" && this.selectedFilters?.length === 0;
      },
      hightLightInput: function() {
        return this.params?.q === this.searchedQuery;
      },
      previousSearch: function() {
        if(this.isPreviousSearch) {
          return 'previous-search';
        }
      },
      querySelectors: function() {
        const fields = document.querySelectorAll("input[data-selector='is-query']")
        const selectors = [];
        fields.forEach(e => {
          selectors.push(e.name);
        });
        return `?q=&${selectors.join("=&")}=`;
      },
    },
    methods: {
      submit: function() {
        document.getElementById('advanced-search-form').submit();
      },
      handleKeyDown: function(event) {
        if(event.key === "Enter") {
          this.submit();
        }
        if(event.target.value === "") {
          this.$root.$emit('search-removed', 'q');
        }
      },

      handleClick: function() {
        if(this.showAdvancedSearch) {
          const path = this.slug[0] === "/" ? this.slug : `/${this.slug}`;
          const query = this.querySelectors;
          window.location.href = `${window.location.origin}${path}/${query}`;
        }else{
          this.showAdvancedSearch = true;
        }
      },
      setValue: function(event){
        this.searchedQuery = event.target.value;
      },
      searchScroll: function() {
        const azRow = document.getElementById("search-index");
        const keywordRow = document.getElementById("search-input")
        if(this.selectedLetter !== "") {
           azRow.scrollIntoView({behavior: "smooth"})
        }else if(this.searchedQuery !== "" || this.selectedFilters?.length > 0) {
          if (this.selectedFilters?.length > 0){
            keywordRow.scrollIntoView({behavior: "smooth"});
          } else {
            keywordRow.scrollIntoView({behavior: "smooth"});
          }
        } else if (this.hasEmptyParams) {
          if (this.params?.post_type) {
            keywordRow.scrollIntoView({behavior: "smooth"});
          } else {
            azRow.scrollIntoView({behavior: "smooth"});
          }
        } else if (this.params?.page && this.params?.page > 1) {
          keywordRow.scrollIntoView({behavior: "smooth"});
        }
      },
    },
  }
</script>

<template>
  <div>
    <slot name="search-index"></slot>
    <div id="search-input" class="row search-input">
      <div class="search-input-keyword " :class="hideAdvancedSearch ? 'simple-search-only col-sm-16 col-md-16 col-lg-14' : 'col-sm-16 col-md-12 col-lg-9' ">
        <div>
          <input type="text" @input="setValue"  name="q" id="q" @keyup='handleKeyDown' :value=this.searchedQuery placeholder="Name and/or keywords" class="input" :class="hightLightInput ? 'highlighted' : ''" >
          <button name="button" type="button" class="search-filter-button" @click='submit'>
            <span aria-hidden="true" class="icon-search-bold"></span>
            <span class="sr-only">Search Names</span></button>
        </div>
      </div>
      <div class="search-input-clear col-sm-16 col-md-4" v-if="!hideAdvancedSearch">
        <div>
          <button class="btn-cta basic advanced-clear-search" type="button" @click="handleClick" :disabled="disableButton">
            {{displayLabel}}
          </button>
        </div>
      </div>
    </div>
    <div class="advanced-search-module" :class='showAdvancedSearch ? "show": ""'>
      <slot name="search-filters"></slot>
    </div>
    <div class="row search-footer">
      <div class="col-sm-16 col-lg-14 search-footer-column">

        <slot name="tags"></slot>

        <div class="search-action" v-if="showAdvancedSearch" :class="previousSearch">
          <button :disabled="disableButton" class="btn-filter" type="submit">{{label}}</button>
        </div>

      </div>
    </div>
  </div>
</template>